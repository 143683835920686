import "./_components.js";
import "./_functions.js";

const header = document.querySelector(".header");
if (header) {
  const onScroll = () => {
    if (window.scrollY > 50) {
      header.classList.add("scroll");
    } else {
      header.classList.remove("scroll");
    }
  };
  document.addEventListener("scroll", onScroll);
  onScroll();
}
