import { burger } from "./functions/burger.js";
import { disableScroll } from "./functions/disable-scroll.js";
import { enableScroll } from "./functions/enable-scroll.js";
import Swiper from "swiper";
import AOS from "aos";
import intlTelInput from "intl-tel-input";
import gsap from "gsap";

new Swiper(".shop-slider", {
  slidesPerView: "auto",
  breakpoints: {
    320: {
      spaceBetween: 16,
    },
    767: {
      spaceBetween: 40,
    },
  },
});

AOS.init({
  once: true,
  duration: 700,
});

const input = document.querySelector("#phone");
let iti;

if (input) {
  iti = intlTelInput(input, {
    initialCountry: "auto",
    countrySearch: false,
    nationalMode: true,
    separateDialCode: true,
    strictMode: true,
    loadUtilsOnInit: `https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js`,
  });

  function validatePhoneNumber() {
    const isValid = iti.isValidNumber();
    if (isValid) {
      input.classList.remove("error");
    } else {
      input.classList.add("error");
    }
  }

  input.addEventListener("input", validatePhoneNumber);
  input.addEventListener("blur", validatePhoneNumber);

  const form = document.querySelector(".modal-form");
  const btns = document.querySelectorAll("[data-button-modal]");
  const modal = document.querySelector(".modal");
  const modalClose = document.querySelector(".modal-close");
  const submitButton = form.querySelector("button[type='submit']");

  form.addEventListener("submit", (event) => {
    // if (iti && !iti.isValidNumber()) {
    event.preventDefault();
    // } else {
    submitButton.disabled = true;
    submitButton.textContent = "Sending";
    // }
  });

  btns.forEach((btn) => {
    btn.addEventListener("click", () => {
      modal.classList.add("show");
    });
  });

  modalClose?.addEventListener("click", () => {
    modal.classList.remove("show");
  });
}

const contactModal = document.querySelector(".dialog");
const contactClose = document.querySelector(".dialog-close");
const contactBtn = document.querySelector(".wfolio-card-trigger");

if (contactModal && contactBtn) {
  contactBtn.addEventListener("click", () => {
    contactModal.classList.add("show");
  });
  contactClose.addEventListener("click", () => {
    contactModal.classList.remove("show");
  });
}

const options = {
  root: null,
  rootMargin: "0px",
  threshold: 0.9,
};

const revealCallback = (entries, self) => {
  entries.forEach((entry) => {
    let container = entry.target;
    let img = entry.target.querySelector("img");
    const easeInOut = "power3.out";
    const revealAnim = gsap.timeline({ ease: easeInOut });

    if (entry.isIntersecting) {
      revealAnim.set(container, {
        visibility: "visible",
      });
      revealAnim.fromTo(
        container,
        {
          clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
          webkitClipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
        },
        {
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          webkitClipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          duration: 1,
          ease: easeInOut,
        }
      );
      revealAnim.from(img, 4, {
        scale: 1.4,
        ease: easeInOut,
        delay: -1,
      });
      self.unobserve(entry.target);
    }
  });
};

let revealObserver = new IntersectionObserver(revealCallback, options);
const revealImages = document.querySelectorAll(".reveal");

if (revealImages.length > 0) {
  revealImages.forEach((reveal) => {
    revealObserver.observe(reveal);
  });
}
